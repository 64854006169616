/**
 * Variables.
 */

/**
 * Colors.
 */
$c-site-bg: #fff;

$c-primary: #93278f;
$c-primary-hover: darken($c-primary, 10%);

$c-secondary: #1087aa;
$c-secondary-hover: darken($c-secondary, 10%);

$c-tertiary: #93278f;
$c-tertiary-hover: darken($c-tertiary, 10%);

$c-accent-1: #ccc;
$c-accent-2: #666;
$c-accent-3: #ccc;

$c-body: #383838;
$c-body-alt: #666;

$c-link: #1087aa;
$c-link-hover: darken($c-link, 10%);

$footer-gray:            #333;
$gray-border:            #ccc;
$gray-base:              #151515 !default;
$gray-darker:            #353535;
$gray-dark:              #545353;
$gray:                   #807e7e;
$gray-light:             #b3b3b3;
$gray-lighter:           #f1f3f2;

/**
 * Fonts.
 */
$f-glyphicons: 'Glyphicons Halflings';
$f-font-awesome: FontAwesome;
$f-open-sans: "Open Sans", Verdana, Arial, sans-serif;

$f-primary: $f-open-sans;
$f-secondary: $f-open-sans;
$f-body: $f-open-sans;

/**
 * Site styles.
 */
$border-radius: 4px;

/**
 * Responsive helpers.
 */
$screen-min: 320px;

// Extra small screen / phone.
$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;

// Small screen / large phone.
$screen-sm-ph: 600px;

// Small screen / tablet.
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;

// Medium screen / desktop.
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;

// Large screen / wide desktop.
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;

// Extra large screen / wider desktop.
$screen-xl: 1560px !default;
$screen-xl-min: $screen-xl !default;

// So media queries don't overlap when required, provide a maximum.
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
