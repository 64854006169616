/**
 * Content.
 */

// Term Bottom Components.
.term-bottom-components {
  margin: 20px 0 0 0;
}

// All Products Sidebar.
.all-products-sidebar {
  margin: 0 0 25px 0;
  padding: 0 0 5px 0;
  border-bottom: 2px solid $c-accent-2;

  > h3 {
    margin: 0 0 5px 0;
    padding: 0;
    font-size: 18px;
    line-height: 1.2;
    color: #fff;
    border-bottom: 2px solid $c-accent-2;

    a {
      display: inline-block;
      margin: 0;
      padding: 8px 15px;
      font-size: 14px;
      line-height: 1.2;
      color: #fff;
      font-weight: bold;
      text-decoration: none;
      background: $c-secondary;
      border-radius: 5px 5px 0 0;
      vertical-align: top;

      &:hover,
      &:focus {
        color: #fff;
        text-decoration: none;
        background: $c-secondary-hover;
      }
    }
  }
  ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: block;
      margin: 0;
      padding: 0;
      background: none;
    }
  }
  // First level.
  .menu-block-wrapper > ul > li {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;

    > a {
      position: relative;
      display: block;
      margin: 0;
      padding: 6px 30px 6px 10px;
      color: $c-body-alt;
      font-size: 12px;
      line-height: 1.2;
      text-decoration: none;
      border-bottom: 1px solid #ccc;

      @media (min-width: $screen-md-min) {
        font-size: 14px;
      }
      // Icon.
      &:after {
        @include fa-icon();
        content: $fa-var-angle-right;
        position: absolute;
        top: 7px;
        right: 10px;
        font-size: 15px;
        color: $c-secondary;
        transition: all .2s ease-in-out;
      }
      &:hover,
      &:focus {
        color: $c-link;

        // Icon.
        &:after {
          right: 8px;
        }
      }
      &.active,
      &.active-trail {

        // Icon.
        &:after {
          content: $fa-var-caret-down;
        }
        &:hover,
        &:focus {
          // Icon.
          &:after {
            right: 10px;
          }
        }
      }
    }
    &:last-child > a {
      border-bottom: none;
    }
  }
  // 2nd level.
  .menu-block-wrapper > ul > li > ul {
    display: block;
    margin: 0;
    padding: 5px 0 10px 0;
    border-bottom: 1px solid #ccc;

    ul {
      display: none;
    }
    > li {
      display: block;
      margin: 0;
      padding: 0;
      border: none;

      > a {
        position: relative;
        display: block;
        margin: 0;
        padding: 3px 0 3px 35px;
        color: $c-secondary;
        font-size: 12px;

        @media (min-width: $screen-md-min) {
          font-size: 13px;
        }
        // Icon.
        &:after {
          @include fa-icon();
          content: $fa-var-angle-right;
          position: absolute;
          top: 5px;
          left: 19px;
          font-size: 15px;
          color: $c-primary;
          transition: all .2s ease-in-out;
        }
        &:hover,
        &:focus {
          // Icon.
          &:after {
            left: 22px;
          }
        }
        &.active,
        &.active-trail {
          // Icon.
          &:after {
            content: $fa-var-caret-down;
          }
          &:hover,
          &:focus {
            // Icon.
            &:after {
              left: 19px;
            }
          }
        }
      }
    }
  }
  .menu-block-wrapper > ul > li:last-child > ul {
    border-bottom: none;
  }
  // 3rd level.
  .menu-block-wrapper > ul > li > ul > li > ul {
    display: block;
    margin: 10px 0;
    padding: 0;
    background: #f7f7f7;

    > li {
      display: block;
      margin: 0;
      padding: 0;

      > a {
        position: relative;
        display: block;
        margin: 0;
        padding: 4px 10px 4px 35px;
        color: $c-secondary;
        font-size: 12px;

        @media (min-width: $screen-md-min) {
          padding: 4px 44px 4px 35px;
          font-size: 13px;
        }
        // Icon.
        &:after {
          @include fa-icon();
          content: $fa-var-angle-right;
          position: absolute;
          top: 6px;
          left: auto;
          right: 30px;
          display: none;
          font-size: 15px;
          color: $c-secondary;
          transition: all .2s ease-in-out;

          @media (min-width: $screen-md-min) {
            display: block;
          }
        }
        &:hover,
        &:focus {
          // Icon.
          &:after {
            right: 28px;
          }
        }
        &.active {
          background: #e9e9e9;

          // Icon.
          &:after {
            content: $fa-var-caret-right;
          }
          &:hover,
          &:focus {
            // Icon.
            &:after {
              right: 30px;
            }
          }
        }
      }
    }
  }
}
