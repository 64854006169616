/**
 * Content Columns.
 */
.paragraphs-item-content-columns {
  clear: both;
  margin: 0;
  padding: 0;

  // Row.
  > .content > .field-name-field-body > .field-items {
    display: block;
    margin: 0 -15px;
    padding: 0;

    @media (min-width: $screen-sm-min) {
      display: flex;
    }
    // Column.
    > .field-item {
      display: block;
      margin: 0 0 20px 0;
      padding: 0 15px;
      width: 100%;
    }
  }
  > .field-name-field-components > .field-label {
    display: none;
  }
}
.paragraphs-items-field-components > .field-name-field-components > .field-label,
.paragraphs-items-field-bottom-components > .field-name-field-bottom-components > .field-label {
  display: none;
}
