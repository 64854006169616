/**
* Site Structure.
*/

/**
* Site Header.
*/
#site-header {
  position: relative;
}
.products-nav {
  float: right;
  margin: 10px 0 0 0;
  padding: 0;

  @media (min-width: $screen-sm-min) {
    margin: 0;
  }
  > ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    font-size: 0;
    list-style: none;
    vertical-align: top;

    > li {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 0;
      font-size: 0;
      background: none;
      vertical-align: top;

      // Line break.
      &:after {
        content: '';
        position: absolute;
        top: 20px;
        right: 0;
        display: block;
        width: 1px;
        height: 22px;
        background: #999;

        @media (min-width: $screen-sm-min) {
          height: 26px;
        }
      }
      &:last-child {
        // Line break.
        &:after {
          display: none;
        }
      }
      > a {
        display: block;
        margin: 0;
        padding: 14px 15px 16px 15px;
        font: bold 18px/23px $f-primary;
        text-align: left;
        text-decoration: none;
        color: #666;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 8px 8px 0 0;

        @media (min-width: $screen-sm-min) {
          padding: 16px 32px 16px 32px;
        }
        &:hover {
          color: $c-primary;
        }
        &:focus {
          color: $c-primary;
        }
      }
      &.is-active > a {
        color: $c-primary;
        text-decoration: none;
        border-color: #ccc;
        border-bottom-color: #f2f2f2;
        background: #fff;
        background: linear-gradient(to bottom, #ffffff 0%, #f2f2f2 100%);
      }
      // Mobile Nav item.
      &.menu__item--mobile-nav {
        display: inline-block;

        @media (min-width: $screen-sm-min) {
          display: none;
        }
        .menu__link-text {
          display: none;
        }
        > a {
          // Icon.
          &:after {
            @include fa-icon();
            content: $fa-var-bars;
            position: relative;
            top: 0;
            margin: 0;
            padding: 0;
            font-size: 28px;
            color: #666;
          }
          &:hover,
          &:focus {
            color: $c-primary;
            text-decoration: none;
            border-color: #fff;
            background: #fff;

            // Icon.
            &:after {
              color: $c-primary;
            }
          }
        }
      }
      // All Products item.
      &.menu__item--all-products {
        display: none;

        @media (min-width: $screen-sm-min) {
          display: inline-block;
        }
        > a {
          position: relative;
          z-index: 11;

          // Icon.
          &:after {
            @include fa-icon();
            content: $fa-var-bars;
            position: relative;
            top: 2px;
            margin: 0 0 0 14px;
            padding: 0;
            font-size: 28px;
            color: $c-primary;
          }
        }
        // Dropdown is active.
        &.is-active > a::after {
          color: #666;
        }
      }
      // Search item.
      &.menu__item--search {
        > a {
          position: relative;
          padding-left: 12px;
          padding-right: 0;
          opacity: 1;

          @media (min-width: $screen-xs-min) {
            padding-left: 15px;
            padding-right: 16px;
          }
          @media (min-width: $screen-sm-min) {
            padding-left: 25px;
          }
          .menu__link-text {
            display: none;
          }
          // Icon.
          &:after {
            @include fa-icon();
            content: $fa-var-search;
            position: relative;
            top: 1px;
            margin: 0;
            padding: 0;
            font-size: 27px;
            color: #666;
          }
          &:hover,
          &:focus {
            color: $c-primary;
            text-decoration: none;
            border-color: #fff;
            background: #fff;

            // Icon.
            &:after {
              color: $c-primary;
            }
          }
          &.active {
            opacity: 0.6;
          }
        }
      }
    }
  }
}
.products-nav-dropdown {
  position: absolute;
  top: 100%;
  right: -75px;
  display: none;
  margin: -1px 0 0 0;
  padding: 20px 10px;
  width: 720px;
  min-height: 300px;
  background: #f2f2f2;
  border: 1px solid #ccc;
  border-bottom: 10px solid #9fcfdd;
  z-index: 10;

  @media (min-width: $screen-md-min) {
    padding: 40px 10px;
    width: 940px;
  }
  @media (min-width: $screen-lg-min) {
    padding: 40px 10px;
    width: 1140px;
  }
  > ul.menu {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
      float: left;
      display: block;
      margin: 0;
      padding: 0 15px;
      width: 25%;

      @media (min-width: $screen-md-min) {
        padding: 0 20px;
      }
      @media (min-width: $screen-lg-min) {
        padding: 0 40px;
      }
      > a {
        display: inline-block;
        margin: 0 0 8px 0;
        padding: 0;
        font-family: $f-primary;
        font-size: 16px;
        line-height: 1.2;
        font-weight: bold;
        color: #666;
        text-decoration: none;
        vertical-align: top;

        @media (min-width: $screen-lg-min) {
          font-size: 20px;
        }
        &:hover,
        &:focus {
          color: $c-link-hover;
          text-decoration: underline;
        }
      }
      // 2nd level.
      > ul.menu {
        display: block;
        margin: 0 0 20px 0;
        padding: 0;
        list-style: none;

        > li {
          margin: 0;
          padding: 0;

          > a {
            position: relative;
            display: inline-block;
            margin: 0;
            padding: 0 0 3px 15px;
            font-size: 13px;
            line-height: 1.2;
            font-weight: normal;
            color: $c-link;
            text-decoration: none;
            vertical-align: top;

            @media (min-width: $screen-md-min) {
              font-size: 14px;
            }
            // Icon.
            &:before {
              @include fa-icon();
              content: $fa-var-chevron-right;
              position: absolute;
              top: 3px;
              left: 0;
              font-size: 10px;
              color: $c-primary;
              transition: all .2s ease-in-out;

              @media (min-width: $screen-md-min) {
                top: 4px;
              }
            }
            &:hover,
            &:focus {
              color: $c-link-hover;
              text-decoration: underline;

              // Icon.
              &:before {
                left: 2px;
              }
            }
          }
          // 3rd level +.
          ul.menu {
            display: none;
          }
        }
      }
    }
  }
}
