/**
 * General.
 */
html,
body {
  height: 100%;
  background: $c-site-bg;
}
body {
  min-width: $screen-min;
}

/**
 * Links.
 */
a {
  @include smooth-transition;

  &:hover,
  &:focus {

  }
}
